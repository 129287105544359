import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import { graphql, useStaticQuery, navigate } from "gatsby";
import media from "styled-media-query";
import {
  AnimatePresence,
  useViewportScroll,
  useTransform,
  motion,
  useSpring,
} from "framer-motion";

import RevealWord from "../../RevealWord";

const Section = styled.section`
  width: 100%;
  height: 100vh;
  min-height: 700px;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  .background-image {
    width: 100%;
    height: 100vh;
  }
`;

const Content = styled(motion.main)`
  position: relative;
  z-index: 2;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  width: 100%;

  ${media.lessThan("medium")`
    padding: 16px;
  `}
`;

const Title = styled.h1`
  line-height: 125px;
  font-size: 80px;
  font-family: "Krona One", sans-serif;
  font-weight: 400;
  letter-spacing: 20px;
  position: relative;
  margin-bottom: 40px;
  text-transform: uppercase;

  ${media.lessThan("medium")`
    font-size: 32px;
    text-align: center;
    line-height: 64px;
    width: 100%;

    div {
    width: 100%;
      text-align: center;
    }
  `};

  :after {
    content: "";
    width: 600px;
    height: 135px;
    border: 5px solid #8effac;
    position: absolute;
    top: 0;
    left: -20px;
    pointer-events: none;
    display: none;
  }
`;

const Cta = styled(motion.button)`
  font-size: 12px;
  text-transform: lowercase;
  font-weight: 400;
  letter-spacing: 2px;
  cursor: pointer;
  position: relative;
  margin-top: 50px;
  border: 0;
  background: none;
  font-family: "Montserrat";

  :hover {
    :after {
      width: 100%;
    }
  }

  :after {
    content: "";
    width: 50%;
    height: 1px;
    position: absolute;
    top: calc(100% + 10px);
    transition: all 800ms ease;
    left: 0;
    background: #8effac;
  }
`;

const Inner = styled.div`
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  text-align: center;
`;

const Text = styled(motion.p)`
  font-family: "Montserrat";
  font-size: 15px;
  max-width: 680px;
  line-height: 1.7;
`;

const PauseButton = styled(motion.button)`
  width: 60px;
  height: 60px;
  background: rgba(0, 0, 0, 0.6);
  position: absolute;
  z-index: 999999999;
  top: 20px;
  right: 32px;
  margin: auto;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: all 900ms ease;
  cursor: pointer;
  border: none;

  :after,
  :before {
    content: "";
    width: 2px;
    height: 30px;
    background: white;
    display: flex;
    flex-flow: row wrap;
    transform: rotate(45deg);
    transform-origin: 50%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
  }

  :after {
    transform: rotate(-45deg);
  }
`;

const VideoContainer = styled(motion.div)`
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  padding: 0;
`;

const VideoPlayer = styled.video`
  width: 100%;
  height: 100vh;
  object-fit: cover;
`;

const Overlay = styled(motion.div)`
  width: 100%;
  height: 100%;
  background: black;
  opacity: 0.82;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
`;

export default ({ about, onVideoOpen }) => {
  const { scrollYProgress } = useViewportScroll();
  const manifesto =
    about?.edges[0]?.node?.resumoManifesto?.childMarkdownRemark?.html;

  const imageOut = useSpring(useTransform(scrollYProgress, [0, 0.16], [1, 0]), {
    bounce: 0,
  });
  const data = useStaticQuery(
    graphql`
      query {
        background: file(relativePath: { eq: "manifesto-bg.png" }) {
          childImageSharp {
            fluid(maxWidth: 2900, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  );

  const videoRef = useRef();

  const videoUrl = `https://videos.ctfassets.net/hos2x6mwmo8b/5Vtb6WfotrG50XyYST93Wb/c343e01a9800963a0b15edfeab2a6459/WhatsApp_Video_2021-03-15_at_17.06.15.mp4`;

  return (
    <Section id="manifesto">
      {/* <BackgroundImage style={{ opacity: imageOut }}>
        <Img fluid={imageData} className="background-image" />
      </BackgroundImage> */}
      <VideoContainer
        style={{ opacity: imageOut }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1, transition: { bounce: 0, delay: 1.3 } }}
        exit={{ opacity: 0 }}
      >
        <Overlay
          initial={{ opacity: 0 }}
          animate={{ opacity: 0.9, transition: { bounce: 0, delay: 0.8 } }}
          exit={{ opacity: 0 }}
        />

        <VideoPlayer
          ref={videoRef}
          width="100%"
          height="100%"
          autoPlay
          muted
          loop
          playsInline
        >
          <source data-lazy-src={videoUrl} type="video/mp4" src={videoUrl} />
        </VideoPlayer>
      </VideoContainer>
      <Content>
        <Inner>
          <Title>
            <RevealWord delay={1}>Lab721</RevealWord>
          </Title>

          <Text
            initial={{ y: 10, opacity: 0 }}
            animate={{
              opacity: 1,
              y: 0,
              transition: { type: "spring", bounce: 0.2, delay: 1.4 },
            }}
            exit={{ opacity: 0, y: -10, pointerEvents: "none" }}
            dangerouslySetInnerHTML={{
              __html: manifesto,
            }}
          />

          <Cta
            onTap={() => navigate("/manifesto")}
            initial={{ opacity: 0, y: 10 }}
            animate={{
              opacity: 1,
              y: 0,
              transition: { delay: 1.8, type: "spring", bounce: 0.2 },
            }}
            exit={{ opacity: 0, y: -10, pointerEvents: "none" }}
            whileHover={{ "letter-spacing": "3px", x: -10 }}
            transition={{
              type: "spring",
              damping: 10,
              stiffness: 20,
            }}
          >
            Assista o manifesto completo
          </Cta>
        </Inner>
      </Content>
    </Section>
  );
};
