import React, {useState} from 'react';
import { graphql } from 'gatsby';

import { AnimatePresence } from 'framer-motion';
import { Page } from '../components/index';
import { Manifesto, Letters, Images } from '../components/About';
import { Solutions } from '../components/Home';
import Menu from '../components/Menu';

const menu = [
  {
    id: 1,
    title: 'Manifesto',
    href: '#manifesto',
  },

  {
    id: 2,
    title: 'O Lab721',
    href: '#o-lab',
  },

  {
    id: 3,
    title: 'Soluções',
    href: '#solucoes',
  },
];

const About = (props) => {
  const { allContentfulAbout, allContentfulServiceCategories } = props.data;
  const [isVideoOpen, setIsVideoOpen] = useState(false);

  return (
    <Page title="Sobre o Lab721">
      <AnimatePresence>{!isVideoOpen && <Menu items={menu} />}</AnimatePresence>

      <Manifesto
        about={allContentfulAbout} 
        onVideoOpen={(isOpen) => setIsVideoOpen(isOpen)}
      />

      <Letters about={allContentfulAbout} />

      <Solutions services={allContentfulServiceCategories} />
    </Page>
  );
};

export const pageQuery = graphql`
  query {
    allContentfulCases {
      edges {
        node {
          id
          title
          description {
            raw
          }
          cover {
            fluid(maxWidth: 700) {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }

    allContentfulAbout(limit: 1) {
      edges {
        node {
          id
          imagens {
            id
            title
            description
            fluid(quality: 100) {
              ...GatsbyContentfulFluid
            }
          }
          primeiroTitulo
          primeiroTexto {
            childMarkdownRemark {
              html
            }
          }
          resumoManifesto {
            childMarkdownRemark {
              html
            }
          }
          segundoTitulo
          segundoTexto {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }

    allContentfulServiceCategories {
      edges {
        node {
          id
          title
          services {
            id
            title
          }
        }
      }
    }
  }
`;

export default About;
