import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';

const Title = styled.h2`
  font-size: calc(114vw / 4);
  transform: translateX(-3vw);
  line-height: calc(77vw / 4);
`;

const Section = styled.section`
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-flow: row wrap;
  background: white;
`;

const Container = styled.main`
  width: 90%;
  max-width: 1227px;
  margin: 0 auto;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-start;
  padding: 200px 0;

  ${media.lessThan('medium')`
    padding: 64px 0;
  `}
`;

const Block = styled.div`
  width: 50%;

  ${media.lessThan('medium')`
    width: 100%;
  `}
`;

const Subtitle = styled.h3`
  font-size: 24px;
  font-family: 'Krona One', sans-serif;
  text-transform: uppercase;
  margin-bottom: 30px;
  color: #161013;
`;

const Texts = styled.div`
  p {
    :not(:first-of-type) {
      margin-top: 21px;
    }
    font-size: 16px;
    font-family: 'Montserrat';
    line-height: 24px;
    color: #161013;
  }
`;

const Letters = ({ about }) => {
  const title = about?.edges[0]?.node?.primeiroTitulo;
  const text = about?.edges[0]?.node?.primeiroTexto?.childMarkdownRemark?.html;

  return (
    <>
      <Title>LAB721</Title>
      <Section id="o-lab">
        <Container>
          <Block>
            <Subtitle>{title}</Subtitle>
          </Block>

          <Block>
            <Texts
              dangerouslySetInnerHTML={{
                __html: text,
              }}
            />
          </Block>
        </Container>
      </Section>
    </>
  );
};

export default Letters;
