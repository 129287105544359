import React from 'react';
import styled from 'styled-components';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import media from 'styled-media-query';
import Masonry from 'react-masonry-component';

const masonryOptions = {
  transitionDuration: 0,
};

const style = {
  width: '100%',
};

const Section = styled.section`
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  overflow: hidden;

  .image {
    margin: calc(15px / 2);
    margin-bottom: 0;

    &:nth-child(3n + 2) {
      width: 27.91vw;
      height: 19vw;
      margin-top: 0;
      opacity: 0.2;
      margin-bottom: calc(15px / 2);

      ${media.lessThan('medium')`
        width: 55.4vw;
        height: 60vw;
        margin: 0;
      `}

    }

    &:nth-child(3n + 1) {
      width: 42.39vw;
      height: 59.8vw;
      margin-right: 0;
      margin-top: 0;
      margin-bottom: 0;

      ${media.lessThan('medium')`
        width: 44.6vw;
        margin: 0;
      `}
    }

    &:nth-child(3n + 3) {
      width: calc(54vw);
      margin-left: 0;
      height: 40vw;
      margin-bottom: 0;

      ${media.lessThan('medium')`
        width: 100vw;
        height: 70vw;
        margin: 0;
      `}
    }
  }
`;

export default ({ about }) => {
  const images = about.edges[0]?.node?.imagens;

  return (
    <Section>
      <Masonry
        className={'my-gallery-class'}
        style={style}
        options={{
          columnWidth: 1,
          horizontalOrder: false,
          originLeft: false,
        }}
      >
        {images.map((image) => {
          return (
            <Img
              key={image.id}
              className="image"
              fluid={image.fluid}
              alt={image.title}
            />
          );
        })}
      </Masonry>
    </Section>
  );
};
